.bg-gradient-info {
  background: linear-gradient(87deg, #d44045 0, #AD1D21  100%) !important
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: none !important;
  }
}

.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
  max-width: 100%;
  max-height: none !important;
}

.loginHeader {
  display: flex;
  justify-content: center;
}

.dropdown {
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  width: 100%;
}

.actionTh {
  text-align: center;
  justify-content: space-between;
}

.actionTd {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.actionTd Button {
  /* padding: 0.3125rem .5125rem !important; */
  font-size: 1rem;
}

.paymentSection, .downloadSection {
  display: flex;
  justify-content: flex-end;
}

.exportBtn {
  padding: 0.3rem 0.6rem;
  margin-top: 10px;
  width: 100px;
}

.noDataImage {
  margin: auto;
  width: 20rem;
  height: auto;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentSection {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.paymentRecordsDownload {
  display: flex;
  justify-content: flex-end;
}

.dateSelector {
  border-radius: 8px;
  border: 1px solid #cad1d7;
  color: #616466;
  height: 42px;
}

.react-datepicker__calendar-icon{
  color: #616466 !important;
  padding-top: 0.8rem !important;
}

.headerSection {
  display: flex;
}

.btnSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.monthBtn {
  padding: 0.3rem 0.6rem;
}

.studentImgPrv {
  width: 90%;
  height: auto;
  max-width: 300px;
}

.nameWithImage{
  justify-content: space-between;
}

.stuImgSection {
  display: flex;
  justify-content: flex-end;
}

.large-checkbox {
  width: 20px;
  height: 20px;
  transform: scale(1.5);
  accent-color: #AD1D21;
}

.eduChecks {
  margin-left: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.resultTable {
  width: 400px;
  border: 1px solid black;
  margin-bottom: 10px;
}

.resultTable thead th, .resultTable td {
  border: 1px solid black;
  font-size: 14px;
}

.radioOptions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.addBtnWithIcon {
  margin-top: 30px;
}

.regNumberBox {
  border: 2px solid black;
  padding: 14px 0px;
  width: 400px;
  margin-bottom: 20px;
  text-align: center;
}

.regNumberBox p {  
  font-size: 16px;
  font-weight: 700;
}

.studentSectionTitles {
  font-size: 22px;
  font-weight: 600;
  color: black;
}

.areaCheck{
  height: 40px;
}

.areaLabel {
  width: 200px;
}

.academicInput {
  width: 80px;
}

.academicInput1 {
  width: 170px;
}

.fdSlash {
  font-size: 2.5em;
}

.boldTitle {
  font-size: 16px;
  font-weight: 600;
}

.custom-rating .fa-star {
  color: #AD1D21; 
}
.custom-rating .fa-star-o {
  color: #d3d3d3; 
}
@media screen and (max-width: 576px) {
  .headerSection {
    flex-direction: column;
    align-items:flex-start;
  }
  .paymentRecordsExport {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .paymentSection {
    align-items: flex-start;
  }
  .paymentRecordsDownload {
    justify-content: flex-start;
  }
  .filterButton {
    margin-top: 20px;
  }
  .wordingSection{
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .headerSection {
    flex-direction: column;
    gap: 10px;
  }
  .btnSection{
    align-items: flex-start;
  }
  .eduChecks{
    display: flex;
    flex-direction: column !important;
  }
}

.dropdownMenuStyle {
  max-height: 400px;
  overflow-y:  auto;
}

@media screen and (max-width: 992px) {
  .nameWithImage{
    display: flex;
    flex-direction: column-reverse !important;
  }

  .studentImgPrv {
    width: 200px;
    height: auto;
  }

  .stuImgSection {
    justify-content: center;
  }
}