.qRCard {
  background-color: white;
  color: #0d173b;
  padding: 10px;
  width: 120px;
  height: 120px;
  border-radius: 0 !important;
}

.cardHeader {
  background-color: white;
  color: #0d173b;
  padding: 10px;
}

.web {
  font-size: 6px;
  padding-top: 3px;
}

.title {
  font-size: 12px;
  padding-bottom: 0px;
  font-weight: bold;
}

.phoneNumber {
  font-size: 10px;
  line-height: 1rem;
}

.regNumber {
  font-size: 8px;
  line-height: 0.8rem;
}

p {
  margin-bottom: 0rem;
}

.emailId {
  text-align: center;
}