.noDataStudent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../img/brand/nodata.png'); 
    background-repeat: no-repeat;
    background-position: center;
}

.student {
    font-family: "Poppins", sans-serif;
    background-color: #f8f9fe;
}

.navNavbar{
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.nav {
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1240px;
    margin: 0 auto;
}

.footer1{
    background-color: #ffffff;
    padding: 20px 0 !important;
}

.footerSection{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;
}

.footerMobile {
    display: none;
}
p {
    font-family: "Poppins", sans-serif;
}

.logoImage {
    width: 80px;
}

.homeLink {
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
}

.stuContent {
    max-width: 1240px;
    margin: 0 auto;
    padding: 32px 16px;
}

.searchCard {
    max-width: 1240px;
    margin: 0 auto;
    padding: 16px 16px 0px 16px;
}

.studentImageSection {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: relative;
    background-color: white;
}

.studentImage {
    width: 300px;
    height: auto;
}

.bioSection {
    display: flex;
    gap: 40px;
    align-items: center;
}

.stuCard {
    padding: 40px;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}

.studentSearchSection {
    padding: 20px;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.studentDetails p {
    font-size: 18px;
    margin-bottom: 8px;
}

.stuStatus{
    margin-top: 16px;
}

.stuBadge {
    font-size: 18px;
    padding: 8px 16px;
    border-radius: 20px;
    margin-right: 10px;
    /* margin-top: 10px; */
}

.areaCheck1 {
    display: flex;
}

.eduQualiSection {
    display: flex;
    flex-direction: column;
}

.eduSection {
    display: flex;
    gap: 20px;
}

.eduBadges {
    display: flex;
    flex-direction: column;
}

.stuTitle {
    color: black;
    font-size: 28px;
    margin-bottom: 30px;
}

.stuTitle1 {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
}

.hrsCard {
    width: 250px;
    box-shadow: 1px 1px 4px rgba(126, 125, 125, 0.1);
}

.hrsHeader{
    font-size: 14px;
    color: black;
    border-bottom: 0px;
    padding-bottom: 0px;
}

.learnedHrs{
    font-size: 50px;
    color: black;
    padding-top: 0px;
}

.learnedHrs1{
    font-size: 50px;
    color: black;
    padding-bottom: 0px;
    padding-top: 0px;
}

.stuCardSection {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.ratingCards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
}

.stuResultTable {
    border: 1px solid black;
    margin-bottom: 10px;
    table-layout: fixed; 
    width: 100%;
}

.stuTableSubject {
    max-width: 300px;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}

.stuTableGrade {
    width: 80px;
    text-align: center !important;
}

.stuTableGradeH{
    padding-left: 11px !important;
}

.stuResultTable thead th, .stuResultTable td {
    border: 1px solid black;
    font-size: 14px;
    padding: 8px;
    text-align: left;
    height: auto;
}

.stuSubTitle {
    color: black;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.stuSubTitle1 {
    color: black;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.stuResultTitle {
    color: black;
    font-size: 18px;
}

.resultprogress {
    max-width: 520px;
}

.recomCard {
    max-width: 550px;
    padding: 20px;
}

.qualiList {
    margin-left: 5px;
}

.stuFullName {
    font-size: 30px;
}

.selector1 {
    width: 180px !important;
}

.react-datepicker__view-calendar-icon input{
    padding-left: 30px !important;
}
.stuNameInitials {
    font-size: 22px;
    font-weight: 200;
}

.svg-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #AD1D21;
    -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
    clip-path: polygon(0 0, 8% 0, 20% 100%, 0% 100%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 0;
  }

  .svg-background2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color:black;
    z-index: 0;
    -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
    clip-path: polygon(8% 0, 12% 0, 24% 100%, 20% 100%);
  }

  .studentCheckbox {
    margin-left: 5px;
    margin-right: 10px;
  }

.searchStudentInput {
    width: 85%;
}

@media (max-width: 576px) {
    .logoImage {
        width: 80px;
        height: auto;
    }
    .areaCheck1{
        flex-direction: column;
        justify-content: left;
    }
    .stuCard {
        padding: 20px;
    }
    .stuBadge {
        margin-top: 5px;
    }
    .footer1 {
        display: none;
    }
    .footerMobile {
        display: flex; 
        background-color: #ffffff;
        padding: 20px 0 !important;
    }
    .footerSection{
        text-align: left;
        margin: 0px;
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .bioSection {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .eduSection {
        flex-direction: column;
    }
    .svg-background, .svg-background2{
        display: none;
    }
    .searchStudentInput {
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .nav {
        max-width: 896px;
        margin: 0 auto;
    }
    .stuContent, .searchCard {
        max-width: 896px;
        margin: 0 auto;
    }
    .footerSection {
        max-width: 896px
    }
}

@media (max-width: 1440px) {
    .nav {
        max-width: 896px;
        margin: 0 auto;
    }
    .stuContent, .searchCard {
        max-width: 896px;
        margin: 0 auto;
    }
    .footerSection {
        max-width: 896px
    }
}
